.contact-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;

}
.form-container{
    width:40vw;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact-input{
    width:50vw;
    padding:5px;
    border:solid grey 1px;
    border-radius: 5px;
}

.comment-input{
    width:50vw;
    height:20vh;
    padding:5px;
    border:solid grey 1px;
    border-radius: 5px;
    font-family: 'DM Sans', sans-serif;
}

button{
    background-color:#3F98FF;
    color:white;
    border:none;
    border-radius:5px;
    width:15vw;
    margin: 10px;
    height:30px;
}

button:hover{
    background-color:#33658A;
    color:white;
}

label{
    font-weight: bold;
}
body{
  font-family: 'DM Sans', sans-serif;
  padding:0;
  margin:0;
  background-color:#FAFCFF;
}
header{
  border: 5px solid #3F98FF;
  background-color:#3F98FF;

  
}

